import React, { useEffect, useState } from "react";
import './carousel.scss';
import Grid from '@mui/material/Grid'; 
import Arrowleft2 from "../../../assets/icons/ArrowLeft2.svg"   
import Arrowright2 from "../../../assets/icons/ArrowRight2.svg"  
const CarouselComponent = (props) => {
    const [carouselData, setcarouselData] = useState([]);
    const [currentData, setcurrentData] = useState([
        {
            imgPath: "",
            title: "",
            details: ""
          },
          {
            imgPath: "",
            title: "",
            details: ""
          },
          {
            imgPath: "",
            title: "",
            details: ""
          },
    ]);
    const [carouselIndex, setcarouselIndex] = useState({
        start: 0,
        end: 3
      });
    useEffect(() => {
        const {start, end} = carouselIndex;
        setcarouselData(props.items);
        generateData(props.items,start,end);
    }, []);

    const generateData =(data,start,end)=>{
        setcurrentData(data.slice(start,end));
    }
    const moveLeft =()=>{
        const {start, end} = carouselIndex;
        if(start > 0){
        generateData(carouselData,start - 1,end - 1);
        setcarouselIndex({
            ...carouselIndex,
            start: start - 1,
            end: end - 1
          });
        }
    }
    const moveRight =()=>{
        
        const {start, end} = carouselIndex;
        if(end < carouselData.length){
        generateData(carouselData,start + 1,end + 1);
        setcarouselIndex({
            ...carouselIndex,
            start: start+1,
            end: end+1
          });
        }
    }
    return (
        <React.Fragment>
             <div className="carousel_sec">
            <Grid container spacing={4}>
              <Grid item xs={4}>
              <div className="carousel_cards" onClick={() =>  window.location.href = "/blog/"+ currentData[0].blogIndex}>
                  <div className="image" style={{backgroundImage: `url(${currentData[0].imgPath})`}}></div>
                  <div className="text">
                      <div className="heading">{currentData[0].title}</div>
                      <div className="desc">{currentData[0].details}</div>
                  </div>
              </div>
              </Grid>
              <Grid item xs={4}>
              <div className="middle_carousel_cards" onClick={() => window.location.href = "/blog/"+ currentData[1].blogIndex}>
              <div className="carousel_cards">
                  <div className="image" style={{backgroundImage: `url(${currentData[1].imgPath})`}}></div>
                  <div className="text">
                      <div className="heading">{currentData[1].title}</div>
                      <div className="desc">{currentData[1].details}</div>
                  </div>
              </div>
              </div>
              </Grid>
              <Grid item xs={4}>
              <div className="carousel_cards" onClick={() => window.location.href = "/blog/"+ currentData[2].blogIndex}>
              <div className="carousel_cards">
                  <div className="image" style={{backgroundImage: `url(${currentData[2].imgPath})`}}></div>
                  <div className="text">
                      <div className="heading">{currentData[2].title}</div>
                      <div className="desc">{currentData[2].details}</div>
                  </div>
              </div>
              </div>
              </Grid>
            </Grid>
            <div className="left_btn" onClick={()=>moveLeft()}><img src={Arrowleft2}/></div>
            <div className="right_btn" onClick={()=>moveRight()}><img src={Arrowright2}/></div>
            </div>
        </React.Fragment>
    );
};
export default CarouselComponent;
