import React, { Component } from "react";
import "./whoWeAre.scss";
import Header from "../common/header/header";
import Banner from "../common/banner/banner";
import Footer from "../common/footer/footer";
import Midbanner from "../common/midBanner/midBanner";
import Lowerbanner from "../common/lowerBanner/lowerBanner";
import Grid from '@mui/material/Grid';
import MidBannerImg from "../../assets/images/midBannerAboutus.png";
import MidBannerImgMb from "../../assets/images/mblbanner3.png";
import BannerImg from "../../assets/images/AboutBanner.png";
import LowerBannerImg from "../../assets/images/FooterImage.png";
import {CONST} from "../../shared/constant"
class WhoWeAre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner_heading: "",
      banner_subtext: "",
      cards: [],
      isMobileView: false
    };
  }
  componentDidMount() {
    var jsonData = require("../../data/content.json");
    this.setState({
      cards: jsonData.who_we_are.cards,
      banner_heading: jsonData.who_we_are.banner_big_heading,
      banner_subtext: jsonData.who_we_are.banner_sub_heading
    })
    this.mobileView();
    window.addEventListener("resize", this.resizeWindow);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeWindow);
  }

  resizeWindow = () => {
    this.mobileView();
  };
  mobileView = () => {
    if (window.innerWidth < 560) {
      this.setState({
        isMobileView: true,
      });
    } else {
      this.setState({
        isMobileView: false,
      });
    }
  };
  render() {
    const {
      banner_heading,
      banner_subtext,
      cards,
      isMobileView
    } = this.state;
    return (
      <React.Fragment>
        <Header mb={isMobileView}/>
        <Banner heading={banner_heading} subText={banner_subtext} bannerImage={BannerImg} mb={isMobileView} btnText="Join Our Team"/>
        <div className="cards_sec">
          <div className="orange_title">OUR VALUES & VISION</div>
          <div className="big_black_heading">Spearhead the development of a world
            in which every activity is simplified
            through an advanced digital solution.</div>
          <div className={isMobileView ? "cards_wrap_mb" : "cards_wrap"}>
            {cards && cards.length > 0 && cards.map((val, i) => {
              return (
                <div className="cards">
                  <div className="heading_black">{val.card_title}</div>
                  <div className="description">{val.card_desc}</div>
                </div>
              )
            })}
          </div>
        </div>
        {isMobileView ?
          <Midbanner midBannerImg={MidBannerImgMb}  mb={isMobileView}/>
          :
        <Midbanner midBannerImg={MidBannerImg}  mb={isMobileView}/>
        }
        <div className="parentWraper">
          <div className="lower_text_sec">
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} sm={6}>
                {isMobileView ? 
                 <div className="big_black_heading">Our power is in
                 our people</div>
                :
                <div className="big_black_heading heading">Our power is in
                  our people</div>
                  }
              </Grid>
              <Grid item xs={12} lg={6} sm={12}>
                <p className="para">Success is a journey, and we travel in good company. We seek those who seek adventure, which is how we continuously raise the bar for ourselves, each other, and our industry. By building a team who truly care, we’re able to achieve change all while enjoying the ride.
                  <br /><br />
                  We’re just a group of people, trying to empower people, to better connect with people.</p>
                <div className="blue_heading" onClick={()=> window.location.href = CONST.PATH.CONTACT_US}>Join our growing team</div>
              </Grid>
            </Grid>
          </div>
        </div>
        <Lowerbanner lowerImage={LowerBannerImg} mb={isMobileView}/>
        <Footer mb={isMobileView}/>
      </React.Fragment>
    );
  }
}

export default WhoWeAre;
