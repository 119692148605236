import React, { Component } from "react";
import emailjs from 'emailjs-com';
import GoogleMapReact from 'google-map-react';
import "./contactUs.scss";
import Header from "../common/header/header";
import Footer from "../common/footer/footer";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import ContactImg1 from "../../assets/icons/ContactIcon1.svg";
import ContactImg2 from "../../assets/icons/ContactIcon2.svg";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Map from "./Map";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AnyReactComponent = ({ text }) => <div className="locationIcon"><LocationOnIcon /></div>;

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personname: "",
      phoneNo: "",
      email: "",
      companyName: "",
      message: "",
      disableSubmit: true,
      isMobileView: false,
      open: false,
      vertical: 'top',
      horizontal: 'right',
      lat: 18.551971378912416,
      lng: 73.77635226141922,
      
      zoom: 18,
      
      center:{
        lat: 18.551971378912416,
        lng: 73.77635226141922,
      }
      
    };
  }
  handaleChange = (name, e) => {
    this.setState({
      [name]: e.target.value
    }, () => {
      this.validationForSubmit();
    })
  }
  validationForSubmit = () => {
    const {phoneNo, personname, email, message } = this.state;
    if (
      phoneNo !== "" &&
      personname !== "" &&
      email !== "" &&
      message !== "" &&
      this.validateEmail(email)
    ) {
      this.setState({
        disableSubmit: false
      })
    } else {
      this.setState({
        disableSubmit: true
      })
    }
  };
  allowNumberPlus = (e) => {
    const code = "charCode" in e ? e.charCode : e.keyCode;
    if (
      code !== 32 && // space
      code !== 43 && // plus
      code !== 45 && // hyphen
      !(code > 47 && code < 58)
    ) {
      e.preventDefault();
    }
  }
  validateEmail(mail) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
  }
  sendEmail = (e) => {
    const { personname, phoneNo, email, companyName, message } = this.state;
    const templateParams = {
      from_name: personname,
      phone_no: phoneNo,
      company_name: companyName,
      message: message,
      email_id: email
    }

    emailjs.send('service_wmnypoc', 'template_ex5xzr9', templateParams, 'user_NTGc31Gl0uDlD4DRjmUur')
      .then((result) => {
        console.log(result.text);
        this.setState({
          open: true
        })
      }, (error) => {
        console.log(error.text);
      });
  };
  componentDidMount() {
    this.mobileView();
    window.addEventListener("resize", this.resizeWindow);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeWindow);
  }

  resizeWindow = () => {
    this.mobileView();
  };
  mobileView = () => {
    if (window.innerWidth < 560) {
      this.setState({
        isMobileView: true,
      });
    } else {
      this.setState({
        isMobileView: false,
      });
    }
  };
  handleClose = () => {
    this.setState({
      open: false
    })
  }
  render() {
    const { disableSubmit,
      isMobileView, open,
      vertical,
      horizontal,lat,lng,zoom,center,} = this.state;
    return (
      <React.Fragment>
        <Header mb={isMobileView} />
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={this.handleClose}
          message="Email Sent Successfully"
          key={vertical + horizontal}
          autoHideDuration={5000}
        >
          <Alert onClose={this.handleClose} severity="success">
            Email Sent Successfully
          </Alert>
        </Snackbar>
        <div className="contactUs_sec">
          <div className="form_wrap">
            <div className="form_sec">
              {isMobileView ? <div className="big_black_heading white_heading_mb">We're here for you. Say hi.</div>
                :
                <div className="big_black_heading white_heading">We're here for you.<br /> Say hi.</div>
              }
              <br />  <br />
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} sm={6}>
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="standard-password-input"
                      label="Your Name*"
                      type="text"
                      variant="standard"
                      onChange={(e) => this.handaleChange("personname", e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6} sm={6}>
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="standard-password-input"
                      label="Email Id*"
                      type="text"
                      variant="standard"
                      onChange={(e) => this.handaleChange("email", e)}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} sm={6}>
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="standard-password-input"
                      label="Phone No*"
                      type="text"
                      variant="standard"
                      onKeyPress={(e) => {
                        this.allowNumberPlus(e);
                      }}
                      onChange={(e) => this.handaleChange("phoneNo", e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6} sm={6}>
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="standard-password-input"
                      label="Company Name"
                      type="text"
                      variant="standard"
                      onChange={(e) => this.handaleChange("companyName", e)}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="standard-password-input"
                  label="Tell us about your project*"
                  type="text"
                  variant="standard"
                  onChange={(e) => this.handaleChange("message", e)}
                />
              </Box>
              <div className={disableSubmit ? "disableBtn" : "contactUs_btn"}>
                <button onClick={() => this.sendEmail()} disabled={disableSubmit}>Submit</button>
              </div>
            </div>
          </div>

        </div>
        <div className="catchUs_sec">
          <div className="big_black_heading" style={{textAlign :"center",marginBottom:"70px"}}>Quick Contact</div>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4} sm={12}>
              <div className="cards">
                <div className="img"> <img src={ContactImg1} alt="Mahira Technology" /> </div>
                <div className="title">For project enquiry</div>
                <div className={isMobileView ? "emailMb" : "email"}><a href="mailto:admin@mahiratechnology.com">admin@mahiratechnology.com</a>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={4} sm={12}>
              <div className="cards">
                <div className="img"> <img src={ContactImg2} alt="Mahira Technology" /> </div>
                <div className="title">For jobs</div>
                <div className={isMobileView ? "emailMb" : "email"}><a href="mailto: contactus@mahiratechnology.com"> contactus@mahiratechnology.com</a>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={4} sm={12}>
              <div className="cards">
                <div className="img"> <img src={ContactImg1} alt="Mahira Technology" /> </div>
                <div className="title">Contact us</div>
                <div className={isMobileView ? "emailMb" : "email"}><a href="tel:+9739782278">+91 97397 82278</a>
                </div>
              </div>
            </Grid>
          </Grid>  
        </div>
        <div className="Map">India Location</div>
        <div style={{ height: '70vh', width: '100%'}}>
        <GoogleMapReact
          defaultCenter={center}
          defaultZoom={zoom}
          bootstrapURLKeys={{ key: "AIzaSyBjCwuBbdZjB-IMFHHSQ8VNXSSnSADH43A"}}
        >
          <AnyReactComponent
            lat={lat}
            lng={lng}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
      <div>
        <Map/>
      </div>
      
        <div className="below_Sec">
                        <div className="big_white_heading">
                        Catch us Candid here
                        </div>
                        <div className="social_media">
                                {/* <div>Facebook</div>
                                <div>Instgram</div> */}
                                 <div><a href="https://twitter.com/MahiraTech1" target="_blank">Twitter</a></div>
                                <div><a href="https://www.linkedin.com/in/mahira-technology-312496230/" target="_blank"> Linkedin</a></div>
                               
                        </div>
                    </div>
        <Footer mb={isMobileView} />
      </React.Fragment>
    );
  }
}

export default ContactUs;
