import React from "react";
import './lowerBanner.scss';
import Grid from '@mui/material/Grid';
import {CONST} from "../../../shared/constant"
const LowerBannerComponent = (props) => {

  return (
    <React.Fragment>
      <section>
        <div className="lower_bannercontainer">
        <Grid container>
              <Grid item xs={12} lg={6} sm={6} className="left_grid">
                <div className="leftPart" className={props.mb ? "leftPart_mb" : "leftPart"}>
                <div className="big_white_heading">
                Interested in digging deeper?
                </div>
                <p className="green_para" onClick={()=> window.location.href = CONST.PATH.CONTACT_US}>Discuss your project with us</p>
                </div>
              </Grid>
              <Grid item xs={12} lg={6} sm={6}>  
              <div className={props.mb ? "imgMb_wraper" : "img_wraper"}>
                <img src={props.lowerImage} />
              </div>
              </Grid>
          </Grid>
        </div>
      </section>
    </React.Fragment>
  );
};
export default LowerBannerComponent;
