import React,{ Component } from "react";
import "./privacypolicy.scss";
import Header from "../common/header/header";
import Footer from "../common/footer/footer";
class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobileView: false,
        };
    }

    componentDidMount() {
        this.mobileView();
        window.addEventListener("resize", this.resizeWindow);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeWindow);
    }

    resizeWindow = () => {
        this.mobileView();
    };
    mobileView = () => {
        if (window.innerWidth < 560) {
            this.setState({
                isMobileView: true,
            });
        } else {
            this.setState({
                isMobileView: false,
            });
        }
    };
    render() {
        const {
            isMobileView,
        } = this.state;
    return (
        <React.Fragment>
             <Header mb={isMobileView} />
            <div className="policy_wrap">
                <div style={{marginBottom:"70px"}}>
                    <div className="big_black_heading">Privacy Policy</div>
                </div>
                <p className="privacy_para">
                Before using our sites, please read this privacy and terms of usage policy
                </p>
                <p className="privacy_para">
                This website (<a href='https://mahiratechnology.com' target='_blank' title="Mahira Technology">https://mahiratechnology.com</a>) is created, owned, operated, and maintained by or for Mahira Technology Pvt. Ltd. (“Company”). This Policy is applicable to Company and sets out our policy on the collecting and use of information on this site and our other sites (collectively “Sites”). The Company is committed to providing safe websites for visitors and has implemented this Privacy Policy to demonstrate our commitment to your privacy. The Company complies with Indian privacy laws on Privacy.
                </p>
                <p className="privacy_para">
                There may be links from our Sites to other websites; note that this Privacy Policy applies only to our Sites and not to websites of other companies/organizations to which our Sites may be linked. You must check on any linked sites for the privacy policy of that site and/or make any necessary inquiries in respect of that privacy policy of the linked site. These links to third-party websites are provided as a convenience and are for informational purposes only. The Company does not endorse and is not responsible for, these linked websites.
                    </p>
                    <p className="privacy_para">
                    Although you are not required to register to access our Sites, you may be asked to provide us with personal information when you visit certain sections of our Sites. Your use of our Sites signifies your acknowledgment and consent to our Privacy Policy. If you do not agree to this Privacy Policy, please do not continue to use our Sites. Your continued use of the Sites signifies your acceptance of these terms and any changes in effect at the time of use.
                    </p>
                    <div className="privacy_heading">
                    Collection of Personal Information
                </div>
                <p className="privacy_para">
                Personal Information is information about you that identifies you as an individual, for example, your name, address, e-mail address, or telephone number.
We collect information that you voluntarily provide to us through responses to contact forms, feedback, surveys, careers, questionnaires, contest forms, complaint forms, and the like.
                    </p>
                    <div className="privacy_heading">
                    Use of Information
                </div>
                <p className="privacy_para">
                Please be advised that any information gathered on our Sites may be used in the aggregate for research and development relating to our Sites and/or for future site development and, if you ask us to, to send you promotional materials. In particular, we may use information gathered about you for the following purposes: to monitor interest in our range of services and to assist us to tailor the content of our Sites to your needs by collecting information about your preferences through tracking of patterns in page views on our Sites; to create a profile relating to you in order to show you the content that might be of interest to you and to display the content according to your preferences; and, in circumstances where you have indicated that you wish to receive additional information, to send you information about us and promotional material about our products.
                    </p>
                    <div className="privacy_heading">
                    Information and Promotion
                </div>
                <p className="privacy_para">
                Information submitted at the time of registration or submission by the online visitors may be used with permission for marketing and promotional purposes by the Company and no other third parties provided notice of this fact is made available online. The Company uses reasonable efforts to maintain visitors’ information in a secure environment. If you have submitted personal information and want to change it or opt out, please contact us. If a visitor objects to such use for any reason, he/she may prevent that use, either by email request or by modifying the registration information provided.
                    </p>
                    <div className="privacy_heading">
                    Disclosure of Information
                </div>
                <p className="privacy_para">
                You may use the website, and the information, writings, images or other content that you see, hear or otherwise experience on the website solely for non-commercial, personal purposes or to know about Company offerings and services. The Company will not disclose personal information that you provide on its Sites to any third parties other than to a Company agent except i) in accordance with the terms of this Privacy Policy, or ii) to comply with legal requirements such as a law, regulation, warrant, or court order, and/or iii) if you are reporting an adverse event/side effect, in which case the Company may be required to disclose such information to bodies such as but not limited to, Indian and/or international regulatory authorities. Please note that any of these disclosures may involve the storage or processing of personal information outside of India and may therefore be subject to different privacy laws than those applicable in India, including laws that require the disclosure of personal information to governmental authorities under circumstances that are different than those that apply in India.
                    </p>
                    <div className="privacy_heading">
                    Protection of Information
                </div>
                <p className="privacy_para">
                We have put in place physical, electronic, and managerial processes to safeguard and help prevent unauthorized access, maintain data security, and correctly use the information we collect online. The Company applies security safeguards appropriate to the sensitivity of the information, such as retaining information in secure facilities and making personal information accessible only to authorized employees on a need-to-know basis.
                </p>
                <div className="privacy_heading">
                Cookies
                </div>
                <p className="privacy_para">
                The Company may use standard technology called “cookies” on its Sites. Cookies are small data files that are downloaded onto your computer when you visit a particular website. You can disable cookies by turning them off in your browser; however, some areas of the Sites may not function properly if you do so.
                </p>
                <div className="privacy_heading">
                Storage of Information
                </div>
                <p className="privacy_para">
                    The personal information you share with us is securely stored with a limited set of trusted third-party application providers. All of these providers have privacy policy agreements that are in alignment with ours, however, many of them are outside of India and have regional variations as required by law.
                </p>
                <div className="privacy_heading">
                Policy Change
                </div>
                <p className="privacy_para">If we alter our Privacy Policy, any changes will be posted on this page of our Site so that you are always informed of the information we collect about you, how we use it, and the circumstances under which we may disclose it.</p>
            </div>
            <Footer mb={isMobileView} />
        </React.Fragment>
    );
    }
};
export default PrivacyPolicy;
