import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import LandingComponent from "./landingpage/landingpage";
import CloudTechnology from "./cloudTechnology/cloudTechnology";
import WhoWeAre from "./whoWeAre/whoWeAre";
import Career from "./career/career";
import Blogs from "./blogs/blogs";
import BlogsDescription from "./blogsDescription/blogsDescription";
import ContactUs from "./contactUs/contactUs";
import Privacy from "./privacypolicy/privacypolicy";
import Terms from "./termofuse/termofuse";
import PageNotFound from "./pagenotfound/pagenotfound";
import { CONST } from '../shared/constant';
function Routing(props) {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path={CONST.PATH.HOME} element={<LandingComponent />} />
                <Route exact path={CONST.PATH.WHAT_WE_DO} element={<CloudTechnology />} />
                <Route exact path={CONST.PATH.WHO_WE_ARE} element={<WhoWeAre />} />
                <Route exact path={CONST.PATH.CAREER} element={<Career />} />
                <Route exact path={CONST.PATH.BLOGS} element={<Blogs />} />
                <Route exact path={CONST.PATH.BLOGS_DESCRIPTION_CC} element={<BlogsDescription/>} />
                <Route exact path={CONST.PATH.CONTACT_US} element={<ContactUs />} />
                <Route exact path={CONST.PATH.POLICY} element={<Privacy />} />
                <Route exact path={CONST.PATH.TERMS} element={<Terms />} />
                <Route exact path='*' element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Routing;
