export const CONST = Object.freeze({
  EMPTY_ANCHOR_TAG: "javascript:;",
  PATH: {
    HOME: "/",
    WHAT_WE_DO: "/what-we-do",
    WHO_WE_ARE: "/who-we-are",
    CAREER: "/career",
    BLOGS:"/blogs",
    BLOGS_DESCRIPTION_CC:"/blog/:cc",
    BLOGS_DESCRIPTION:"/blog/",
    CONTACT_US: "/contact-us",
    POLICY: "/privacy-policy",
    TERMS: "/terms"
  },
  PAGE_NAME:{
    HOME: "Home",
    WHAT_WE_DO: "What We Do",
    WHO_WE_ARE: "Who We Are",
    CAREER: "Careers",
    BLOGS: "Blogs",
    CONTACT_US: "Contact Us"
  },
  CONTACT_INFO:{
    PHONE:"+91 97397 82278",
    CONTACT_EMAIL:"contactus@mahiratechnology.com",
    JOB_EMAIL:"jobs@mahiratechnology.com"
  }
});
