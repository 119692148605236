import React, { Component } from "react";
import './blogsDescription.scss';
import Header from "../common/header/header";
import Footer from "../common/footer/footer";
import { BLOGDATA } from "../blogs/blogs_data";
import {CONST} from "../../shared/constant";
class BusinessBlogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobileView: false,
            setBlogDesc:{},
            isBlogAvailable:false,
            blogImg:""
        };
    }

    componentDidMount() {
        this.mobileView();
        window.addEventListener("resize", this.resizeWindow);
        BLOGDATA.businessBlog.forEach((val,id)=>{
            if(this.props.params == id+1){
                this.setState({
                    setBlogDesc:val,
                    isBlogAvailable: true,
                    blogImg:val.imgPath
                })
            }
        })
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeWindow);
    }

    resizeWindow = () => {
        this.mobileView();
    };
    mobileView = () => {
        if (window.innerWidth < 560) {
            this.setState({
                isMobileView: true,
            });
        } else {
            this.setState({
                isMobileView: false,
            });
        }
    };
   
    render() {
        const {
            isMobileView,
            isBlogAvailable,
            setBlogDesc,
            blogImg
        } = this.state;
        return (
            <React.Fragment>
                <Header mb={isMobileView} />
                {
                    isBlogAvailable ? 
                    <div className="parentPadding">
                        <div className="blog_desc_wrap">
                           
                            {setBlogDesc.blog && setBlogDesc.blog.map((ele,i)=>{
                                return(
                                    <div>
                                            <div className={i===0 ? "big_blog_heading" : "blog_heading"}>{ele.heading}</div>
                                            {i === 0 &&   <img className="blog_img" src={blogImg} />}
                                            {ele.desc.length > 0 && ele.desc.map((val,id)=>{
                                                return(
                                                    <div className="blog_para">
                                                        {val.para}
                                                    </div>
                                                )
                                            })}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    :
                    <div className="parentPadding">
                       <div className="blog_desc_wrap">
                           <div className="no_blogsWrap">
                               <div className="oops">Oops!</div>
                               <div className="no_mesg">The blog you're looking for no longer exists.</div>
                               <div className="no_mesg">Return to <a href="javascript:;" onClick={() => { window.location.href = CONST.PATH.BLOGS }}>blog page</a>.</div>
                           </div>
                        </div> 
                    </div>
                }
                
                <Footer mb={isMobileView} />
            </React.Fragment>
        );
    }
}

export default BusinessBlogs;
