import GoogleMapReact from 'google-map-react';
import React, { Component } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './contactUs.scss';

const AnyReactComponent = ({ text }) => <div className="locationIcon"><LocationOnIcon /></div>;
class Map extends Component {
    constructor(props){
        super(props);
        this.state = {
            lat: 43.698850,
            lng: -79.440530,
            zoom: 18,
            center:{
                lat: 43.698850,
                lng: -79.440530
              }
        };
    }
    componentDidMount() {
        this.mobileView();
        window.addEventListener("resize", this.resizeWindow);
      }
      componentWillUnmount() {
        window.removeEventListener("resize", this.resizeWindow);
      }
    
      resizeWindow = () => {
        this.mobileView();
      };
      mobileView = () => {
        if (window.innerWidth < 560) {
          this.setState({
            isMobileView: true,
          });
        } else {
          this.setState({
            isMobileView: false,
          });
        }
      };
      render() {
        const {
          isMobileView,lat,lng,zoom,center} = this.state;
        return (
          <React.Fragment>
            <div className='Map2'>Canada Location</div>
            <div style={{ height: '70vh', width: '100%'}}>
        <GoogleMapReact
          defaultCenter={center}
          defaultZoom={zoom}
          bootstrapURLKeys={{ key: "AIzaSyBjCwuBbdZjB-IMFHHSQ8VNXSSnSADH43A"}}
        >
          <AnyReactComponent
            lat={lat}
            lng={lng}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
          </React.Fragment>
        );
    }
}

export default Map;
