import React from "react";
import {useParams} from "react-router-dom";
import BusinessBlog from "./businessBlogs";
const BlogsDescription = (props) => {
    const params = useParams();
    console.log("params",params)
    return (
        <React.Fragment>
            <BusinessBlog params={Number(params.cc)}/>
        </React.Fragment>
    );
};
export default BlogsDescription;
