import React from "react";
import './midBanner.scss';

const LowerBannerComponent = (props) => {

  return (
    <React.Fragment>
      <section className="mid_bannercontainer">
      <div className={props.mb ? "midBannerMb_wrap" : "midBanner_wrap"} style={{backgroundImage: `url(${props.midBannerImg})`}}>
           </div>
      </section>
    </React.Fragment>
  );
};
export default LowerBannerComponent;
