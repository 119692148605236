import React,{ Component } from "react";
import "./termofuse.scss";
import Header from "../common/header/header";
import Footer from "../common/footer/footer";
class TermofUse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobileView: false,
        };
    }

    componentDidMount() {
        this.mobileView();
        window.addEventListener("resize", this.resizeWindow);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeWindow);
    }

    resizeWindow = () => {
        this.mobileView();
    };
    mobileView = () => {
        if (window.innerWidth < 560) {
            this.setState({
                isMobileView: true,
            });
        } else {
            this.setState({
                isMobileView: false,
            });
        }
    };
    render() {
        const {
            isMobileView,
        } = this.state;
    return (
        <React.Fragment>
             <Header mb={isMobileView} />
            <div className="term_wrap">
                <div style={{marginBottom:"70px"}}>
                    <div className="big_black_heading">Terms and Usage</div>
                </div>
                <p className="term_para">
                Please read the following terms and conditions carefully. They govern your access and use of this website. By accessing or using this website, you agree to be bound by these terms and conditions and accept them in full, as they may be modified by Mahira Technology from time to time and posted on this website.
                </p>
                <p className="term_para">
Information on this Web site is not promised or guaranteed to be correct, current, or complete, and this site may contain technical inaccuracies or typographical errors. Mahira Technology assumes no responsibility (and expressly disclaims responsibility) for updating this site to keep information current or to ensure the accuracy or completeness of any posted information. Accordingly, you should confirm the accuracy and completeness of all posted information before making any decision related to any services, products, or other matters described on this site.
                </p>
                <div className="term_heading">
                No Warranties:
                </div>
                <p className="term_para">
                The website and its contents are provided on an “as is” basis. Use of the website and its contents is at the user’s sole risk. The website and its contents are provided without any representations, endorsements, or warranties of any kind whatsoever, either express or implied, including, but not limited to, any warranties of title or accuracy and any implied warranties of merchantability, fitness for a particular purpose, or non-infringement, with the sole exception of warranties (if any) which cannot be expressly excluded under applicable law. as noted below, Mahira Technology also makes no representations, endorsements, or warranties, either express or implied, with respect to any website operated by a third party.
                </p>
                <div className="term_heading">
                Use of Website:</div>
                <p className="term_para">
                Contents are included in this Website solely for the personal use of Website users. You may not copy (other than a copy for personal use), modify, distribute, transmit, display, perform, reproduce, transfer, resell, or republish any of the Contents of this Website without the prior written consent of Mahira Technology, which may be withheld in its sole discretion. We may limit or restrict access to our website’s data, downloads, accounts, content, and can keep any user or group of users away from our website, defined through our Terms of Use of the website.
                    </p>
                    <p className="term_para">
                    Copyrights and Other Intellectual Property: The image(s) used in Mahira Technology managed website(s)are copyrighted and protected by iStock Images. The image(s) shall remain the sole and exclusive property of iStock Images or its licensors. Except where otherwise expressly noted, all Contents of this Website, including white papers, case studies, graphics, icons, and the overall appearance of the Website, are the sole and exclusive property of Mahira Technology. All other trademarks are the property of their respective owners.
                    </p>
                    <div className="term_heading">
                    Limitation of Liability:
                </div>
                <p className="term_para">
                In no event will Mahira Technology be liable to any party for any direct, indirect, incidental, special, exemplary, or consequential damages of any type whatsoever related to or arising from this website or any use of this website, or of any site or resource linked to, referenced, or accessed through this web site, or for the use or downloading of, or access to, any materials, information, products, or services, including, without limitation, any lost profits, business interruption, lost savings or loss of programs or other data, even if Mahira Technology is expressly advised of the possibility of such damages. This exclusion and waiver of liability apply to all causes of action, whether based on contract, warranty, tort, or any other legal theories.
                    </p>
            </div>
            <Footer mb={isMobileView} />
        </React.Fragment>
    );
    }
};
export default TermofUse;
