import React, { useEffect, useState } from "react";
import './logoslider.scss';
import Grid from '@mui/material/Grid'; 
import Arrowleft2 from "../../../assets/icons/ArrowLeft2.svg"   
import Arrowright2 from "../../../assets/icons/ArrowRight2.svg" 
import Gcp from "../../../assets/logos/gcp.png";  
import Terraform from "../../../assets/logos/terraform.png"; 
import Docker from "../../../assets/logos/docker.png"  
import Kubernetes from "../../../assets/logos/kubernetes.png"  
import Ansible from "../../../assets/logos/ansible.png"  
import Packer from "../../../assets/logos/packer.png"  
import Jenkins from "../../../assets/logos/jenkins.png"  
import Azure from "../../../assets/logos/azure.png"  
import Aws from "../../../assets/logos/aws.png"  
import Open from "../../../assets/logos/open.png"  
import Grafana from "../../../assets/logos/grafana.png"  
import Prometheus from "../../../assets/logos/prometheus.png"  
import Jira from "../../../assets/logos/jira.png"  
import Gitlab from "../../../assets/logos/gitlab.png"  
import Bitbucket from "../../../assets/logos/bitbucket.png"  
const Logoslider = (props) => {
    const [carouselData, setcarouselData] = useState([
        Azure,Aws,Gcp,Terraform,Docker,Kubernetes,Ansible,Packer,Jenkins,Bitbucket,Gitlab,Jira,Prometheus,Grafana,Open
    ]);
    const [currentData, setcurrentData] = useState([
        {
            imgPath: ""
          },
          {
            imgPath: ""
          },
          {
            imgPath: ""
          },
          {
            imgPath: ""
          }
    ]);
    const [currentDataMb, setcurrentDataMb] = useState([
      {
        imgPath: ""
      },
      {
        imgPath: ""
      },
      {
        imgPath: ""
      },
      {
        imgPath: ""
      }
  ]);
    const [carouselIndex, setcarouselIndex] = useState({
        start: 0,
        end: 5
      });
      const [mobileIndex, setmobileIndex] = useState({
        mbstart: 0,
        mbend: 1
      });
    useEffect(() => {
        const {start, end} = carouselIndex;
        const {mbstart, mbend} = mobileIndex;
        if(props.mb){
          generateDataMb(carouselData,mbstart,mbend);
        }else{
        generateData(carouselData,start,end);
        }
    }, [props.mb]);

    const generateDataMb =(data,start,end)=>{
      setcurrentDataMb(data.slice(start,end));
     }
    const generateData =(data,start,end)=>{
        setcurrentData(data.slice(start,end));
    }
    const moveLeft =()=>{
        const {start, end} = carouselIndex;
        if(start > 0){
        generateData(carouselData,start - 1,end - 1);
        setcarouselIndex({
            ...carouselIndex,
            start: start - 1,
            end: end - 1
          });
        }
    }
    const moveRight =()=>{
        
        const {start, end} = carouselIndex;
        if(end < carouselData.length){
        generateData(carouselData,start + 1,end + 1);
        setcarouselIndex({
            ...carouselIndex,
            start: start+1,
            end: end+1
          });
        }
    }
    const moveLeftMb =()=>{
      const {mbstart, mbend} = mobileIndex;
      if(mbstart > 0){
        generateDataMb(carouselData,mbstart - 1,mbend - 1);
      setmobileIndex({
          ...mobileIndex,
          mbstart: mbstart - 1,
          mbend: mbend - 1
        });
      }
  }
  const moveRightMb =()=>{
      
    const {mbstart, mbend} = mobileIndex;
      if(mbend < carouselData.length){
        generateDataMb(carouselData,mbstart + 1,mbend + 1);
      setmobileIndex({
          ...mobileIndex,
          mbstart: mbstart+1,
          mbend: mbend+1
        });
      }
  }
    return (
        <React.Fragment>
          {!props.mb ?
             <div className="logoslider_sec">
            <Grid container spacing={4}>
            <Grid item lg={1}>
            <div className="left_btn" onClick={()=>moveLeft()}><img src={Arrowleft2}/></div>
              </Grid>
              <Grid item lg={2}>
              <div className="logos">
                 <img src={currentData[0]}/>
              </div>
              </Grid>
              <Grid item lg={2}>
              <div className="logos" >
              <img src={currentData[1]}/>
              </div>
              </Grid>
              <Grid item lg={2}>
              <div className="logos" >
              <img src={currentData[2]}/>
              </div>
              </Grid>
              <Grid item lg={2}>
             
              <div className="logos">
              <img src={currentData[3]}/>
              </div>
              </Grid>
              <Grid item lg={2}>
             
              <div className="logos">
              <img src={currentData[4]}/>
              </div>
              </Grid>
              <Grid item lg={1}>
              <div className="right_btn" onClick={()=>moveRight()}><img src={Arrowright2}/></div>
              </Grid>
             
            </Grid>
            </div>
             :
            <div className="logoslider_sec">
            <Grid container spacing={4}>
            <Grid item xs={3}>
            <div className="left_btn" onClick={()=>moveLeftMb()}><img src={Arrowleft2}/></div>
              </Grid>
              <Grid item xs={6}>
              <div className="logos">
                 <img src={currentDataMb[0]}/>
              </div>
              </Grid>
              {/* <Grid item xs={3}>
              <div className="logos" >
              <img src={currentDataMb[1]}/>
              </div>
              </Grid> */}
              {/* <Grid item xs={3}>
              <div className="logos" >
              <img src={currentDataMb[2]}/>
              </div>
              </Grid> */}
              <Grid item xs={3}>
              <div className="right_btn" onClick={()=>moveRightMb()}><img src={Arrowright2}/></div>
              </Grid>
             
            </Grid>
            </div>}
        </React.Fragment>
    );
};
export default Logoslider;
