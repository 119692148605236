import React from "react";
import './footer.scss';
import Grid from '@mui/material/Grid';
import Mahira from "../../../assets/images/mahira_footer.png";
import Simplify from "../../../assets/images/Simplifying.png";
import Facebook from "../../../assets/icons/facebook.svg";
import Instagram from "../../../assets/icons/instagram.svg";
import Linkedin from "../../../assets/icons/Linkedin.svg";
import Twitter from "../../../assets/icons/twitter.svg";
import Copyright from "../../../assets/icons/copyright.svg";
import { CONST } from '../../../shared/constant';
const FooterComponent = (props) => {

  const navigatePages = (url) => {
    window.location.href = url;
  }
  return (
    <React.Fragment>
      <section className="parentWraper">
        <div className="footerWrap">
          <Grid container spacing={2}>
          <Grid item xs={12} lg={4} sm={12}>
              <div><img src={Mahira}/></div>
              <div className="simplify_img"><img src={Simplify}/></div>
            </Grid>
            <Grid item xs={12} lg={2} sm={12}>
              <div className="footer_tabs" onClick={() => navigatePages(CONST.PATH.HOME)}>{CONST.PAGE_NAME.HOME}</div>
              <div className="footer_tabs" onClick={() => navigatePages(CONST.PATH.WHAT_WE_DO)}>{CONST.PAGE_NAME.WHAT_WE_DO}</div>
              <div className="footer_tabs" onClick={() => navigatePages(CONST.PATH.WHO_WE_ARE)}>{CONST.PAGE_NAME.WHO_WE_ARE}</div>
            </Grid>
            <Grid item xs={12} lg={2} sm={12}>
              <div className="footer_tabs" onClick={() => navigatePages(CONST.PATH.CAREER)}>{CONST.PAGE_NAME.CAREER}</div>
              <div className="footer_tabs" onClick={() => navigatePages(CONST.PATH.BLOGS)}>{CONST.PAGE_NAME.BLOGS}</div>
              <div className="footer_tabs" onClick={() => navigatePages(CONST.PATH.CONTACT_US)}>{CONST.PAGE_NAME.CONTACT_US}</div>
            </Grid>
            <Grid item xs={12} lg={4} sm={12}>
              <div className="footer_tabs"><a href="tel:+9739782278">+91 97397 82278</a></div>
              <div className="footer_tabs"><a href="mailto:contactus@mahiratechnology.com">contactus@mahiratechnology.com</a></div>
              {/* <div className="footer_tabs"><a href="mailto:jobs@mahiratechnology.com">jobs@mahiratechnology.com</a></div> */}
            </Grid> 
          
          </Grid>
          <div className="hr_line"></div>
          <Grid container spacing={2}>
          <Grid item xs={12} lg={4} sm={12}>
          <div className="social_icon">
                <div><a href="https://twitter.com/MahiraTech1" target="_blank"><img src={Twitter} alt="Mahira Technology" /></a> </div>
                <div><a href="https://www.linkedin.com/in/mahira-technology-312496230/" target="_blank"> <img src={Linkedin} alt="Mahira Technology" /></a></div>
                {/* <div> <img src={Facebook} alt="Mahira Technology"/></div>
                 <div> <img src={Instagram} alt="Mahira Technology"/></div> */}
              </div>
            </Grid>
            <Grid item xs={12} lg={4} sm={12}>
            <div className= "copyright_sec">
                <img src={Copyright} alt="Mahira Technology" />
                <span className="rights_msg">All rights reserved by Mahira Technology</span>
              </div>
            </Grid>
            <Grid item xs={12} lg={2} sm={12}>
              <div className="terms" onClick={() => navigatePages(CONST.PATH.POLICY)}>Privacy Policy</div>
            </Grid>
            <Grid item xs={12} lg={2} sm={12}>
            <div className="terms" onClick={() => navigatePages(CONST.PATH.TERMS)}>Terms of Use</div>
            </Grid>
          
          </Grid>
            
        </div>
      </section>
    </React.Fragment>
  );
};
export default FooterComponent;
