import React from "react";
import "./landingpage.scss";
// import Clientsimg1 from "../../assets/images/pharmait.png";
// import Clientsimg2 from "../../assets/images/deliforce.png";
// import Clientsimg3 from "../../assets/images/vayuz.png";
// import Clientsimg4 from "../../assets/images/newpage.png";
// import Clientsimg5 from "../../assets/images/labs.png";
// import Clientsimg6 from "../../assets/images/flycatch.svg";
// import Clientsimg7 from "../../assets/images/Simplifying.png";
// import Clientsimg8 from "../../assets/images/nextbrain.png";
// import Clientsimg9 from "../../assets/images/dntnew.png";
// import Logoslider1 from "../landingpage/clientlogoslider.jsx";
// import Arrowleft2 from "../../assets/icons/ArrowLeft2.svg";
// import Arrowright2 from "../../assets/icons/ArrowRight2.svg";
import "../common/logoslider/logoslider.scss";
import Grid from '@mui/material/Grid';
import Logoslider1 from "./clientlogoslider";
const BannerComponent = (props) => {
   return (
    <React.Fragment>
      <section>
      <div className="clients_wrap">
      <Grid container spacing={3}>
      <Grid item xs={12} lg={2} sm={2} > 
      </Grid>
              {/* <Grid item xs={12} lg={2} sm={2} >
              <div className="client_img_wrap">
                {/* img-deliforce */}
              {/* <img src={Clientsimg2} alt="Mahira Technology"/>
              </div>
              </Grid> */} 
              {/* <Grid item xs={12} lg={2} sm={2} >
              <div className="client_img_wrap"> */}
                {/* img-vayuz */}
              {/* <img src={Clientsimg3} alt="Mahira Technology"/>
              </div>
              </Grid> */}
              {/* <Grid item xs={12} lg={2} sm={2}>
              <div className="client_img_wrap"> */}
                {/* img-newpage */}
              {/* <img src={Clientsimg4} alt="Mahira Technology"/>
              </div>
              </Grid> */}
              {/* <Grid item xs={12} lg={2} sm={2}>
              <div className="client_img_wrap"> */}
                {/* img-lab */}
              {/* <img src={Clientsimg5} alt="Mahira Technology"/>
              </div>
              </Grid> */}
              {/* <Grid item xs={12} lg={2} sm={2}>
                <div className="client_img_wrap"> */}
                  {/* img-flycatch */}
                {/* <img src={Clientsimg6} alt="Mahira Technology"/>
             </div>
              </Grid> */}
              {/* <Grid item xs={12} lg={2} sm={2}>
                <div className="client_img_wrap"> */}
                  {/* img-pharmaIT */}
                {/* <img src={Clientsimg1} alt="Mahira Technology"/>
             </div>
              </Grid> */}
              {/* <Grid item xs={12} lg={2} sm={2}>
                <div className="client_img_wrap"> */}
                  {/* img-simplifying */}
                {/* <img src={Clientsimg7} alt="Mahira Technology"/>
             </div>
              </Grid> */}
              {/* <Grid item xs={12} lg={2} sm={2}>
                <div className="client_img_wrap"> */}
                  {/* img-nextbrain */}
                {/* <img src={Clientsimg8} alt="Mahira Technology"/>
             </div>
              </Grid> */}
              {/* <Grid item xs={12} lg={2} sm={2}>
                <div className="client_img_wrap"> */}
                  {/* img- Dnt_infotech */}
                  {/* <img src={Clientsimg9} alt="Mahira Technology"/>
                </div>
              </Grid> */}
              {/* <Grid item xs={12} lg={2} sm={2}>
                <div className="client_img_wrap"> */}
                  {/* img-Autimo_logo */}
                  {/* <img src={Clientsimg10} alt="Mahira Technology"/>
                </div>
              </Grid> */}
                   
      </Grid> 
      <Logoslider1 />
      </div> 
      </section>
    </React.Fragment>
  );
};
export default BannerComponent;
