import React, { useEffect, useState } from "react";
import './carouselMb.scss';
import Arrowleft2 from "../../../assets/icons/ArrowLeft2.svg"   
import Arrowright2 from "../../../assets/icons/ArrowRight2.svg"  
const CarouselMbComponent = (props) => {
    const [carouselData, setcarouselData] = useState([]);
    const [currentData, setcurrentData] = useState([
        {
            imgPath: "",
            title: "",
            details: ""
        }
    ]);
    const [carouselIndex, setcarouselIndex] = useState(0);
    useEffect(() => {
        setcarouselData(props.items);
        generateData(props.items,carouselIndex);
    }, []);

    const generateData =(data,start)=>{
        setcurrentData(data[start]);
    }
    const moveLeft =()=>{
        if(carouselIndex > 0){
        generateData(carouselData,carouselIndex - 1);
        setcarouselIndex(carouselIndex - 1);
        }
    }
    const moveRight =()=>{   
        if(carouselIndex < carouselData.length - 1){
        generateData(carouselData,carouselIndex + 1);
        setcarouselIndex(carouselIndex + 1);
        }
    }
    return (
        <React.Fragment>
             <div className="carouselMb_sec">
              <div className="carousel_cards" onClick={() =>  window.location.href = "/blog/"+ currentData.blogIndex}>
                  <div className="image" style={{backgroundImage: `url(${currentData.imgPath})`}}></div>
                  <div className="text">
                      <div className="heading">{currentData.title}</div>
                      <div className="desc">{currentData.details}</div>
                  </div>
              </div>
            <div className="left_btn" onClick={()=>moveLeft()}><img src={Arrowleft2}/></div>
            <div className="right_btn" onClick={()=>moveRight()}><img src={Arrowright2}/></div>
            </div>
        </React.Fragment>
    );
};
export default CarouselMbComponent;
