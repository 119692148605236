// import {react component} from react
// import {header,footer,banner} from different folder
// import {img } from different folder 
// import {const, blogdata} from folder

import React, { Component } from "react";
import "./landingpage.scss";
import Header from "../common/header/header";
import Banner from "../common/banner/banner";
import Clients from "./clients";
import Footer from "../common/footer/footer";
import Midbanner from "../common/midBanner/midBanner";
import Lowerbanner from "../common/lowerBanner/lowerBanner";
import Carousel from "../common/carousel/carousel";
import CarouselMb from "../common/carouselMb/carouselMb";
import Slider from "../common/slider/slider";
import Grid from '@mui/material/Grid';
import MidBannerImg from "../../assets/images/midBannerHome.png";
import MidBannerImgMb from "../../assets/images/mblbanner1.png";
import BannerImg from "../../assets/images/HomeBanner.png";
import LowerBannerImg from "../../assets/images/FooterImage.png";
import HomeImage1 from "../../assets/images/HomeImage1.png";
import HomeImage2 from "../../assets/images/HomeImage2.png";
import HomeImage3 from "../../assets/images/HomeImage3.png";
import {CONST} from "../../shared/constant";
import { BLOGDATA } from "../blogs/blogs_data";


// creating  class component and setting state


class LandingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner_heading: "",
      banner_subtext: "",
      content: {},
      isMobileView: false,
    };
  }
  componentDidMount() {
    this.mobileView();
    window.addEventListener("resize", this.resizeWindow);
    var jsonData = require("../../data/content.json");
    this.setState({
      banner_heading: jsonData.landing_page.banner_big_heading,
      banner_subtext: jsonData.landing_page.banner_sub_heading,
      content: jsonData.landing_page
    })
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeWindow);
  }

  // setting different screen size 

  resizeWindow = () => {
    this.mobileView();
  };
  mobileView = () => {
    if (window.innerWidth < 1200) {
      this.setState({
        isMobileView: true,
      });
    } else {
      this.setState({
        isMobileView: false,
      });
    }
  };
  render() {
    const {
      banner_heading,
      banner_subtext,
      content,
      isMobileView
    } = this.state;
    return (
      <React.Fragment>
        <Header mb={isMobileView}/>
        <Banner heading={banner_heading} subText={banner_subtext} bannerImage={BannerImg} mb={isMobileView} btnText="Schedule a Demo"/>
        <Clients mb={isMobileView}/>
        <div className="middleContentWrap parentWraper">
          <div className="big_black_heading">{content.middle_big_heading}</div>

          {/* remove !(not) from mobileView for overlapping issue*/}

         {!isMobileView ?
         <div>

          {/* innovation on point DEVOPS Content using grid  */}

          <div className="mid_sec">
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} sm={12}>
                <div className="left_img">
                  <img src={HomeImage1} alt="devops" />
                </div>
              </Grid>
              <Grid item xs={12} lg={6} sm={12} className="content_grid">
                <div className="content_sec">
                  <div className="title">{content.devops_title}</div>
                  <p className="desc">{content.devops_desc}</p>
                  <div className="knowMore_btn" onClick={() => { window.location.href = CONST.PATH.WHAT_WE_DO }}>Know More</div>
                </div>
              </Grid>
            </Grid>
          </div>

          {/* innovation on point CLOUD Content using grid */}

          <div className="mid_sec">
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} sm={12} className="content_grid">
                <div className="content_sec">
                  <div className="title">{content.cloud_title}</div>
                  <p className="desc">{content.cloud_desc}</p>
                  <div className="knowMore_btn" onClick={() => { window.location.href = CONST.PATH.WHAT_WE_DO }}>Know More</div>
                </div>
              </Grid>
              <Grid item xs={12} lg={6} sm={12}>
                <div className="right_img">
                  <img src={HomeImage2} alt="Cloud" />
                </div>
              </Grid>
            </Grid>
          </div>

          {/* innovation on point PRODUCTDESIGN Content using grid */}

          <div className="mid_sec">
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} sm={12}>
                <div className="left_img">
                  <img src={HomeImage3} alt="productDesign" />
                </div>
              </Grid>
              <Grid item xs={12} lg={6} sm={12} className="content_grid">
                <div className="content_sec">
                  <div className="title">{content.product_design_title}</div>
                  <p className="desc">{content.product_design_desc}</p>
                  <div className="knowMore_btn">Know More</div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        :
        <div>

          {/* innovation on point DEVOPS IMAGE Content using grid */}

            <div className="mid_secMb">
                <div className="title">{content.devops_title}</div>
                <div className="topImg">
                  <img src={HomeImage1} alt="devops" />
                </div>
                <div className="contentMb">
                  <p className="desc">{content.devops_desc}</p>
                  <div className="knowMore_btn" onClick={() => { window.location.href = CONST.PATH.WHAT_WE_DO }}>Know More</div>
                </div>
          </div>

          {/* innovation on point CLOUD IMAGE Content using grid */}

          <div className="mid_secMb">
                <div className="title">{content.cloud_title}</div>
                <div className="topImg">
                  <img src={HomeImage2} alt="cloud" />
                </div>
                <div className="contentMb">
                  <p className="desc">{content.cloud_desc}</p>
                  <div className="knowMore_btn" onClick={() => { window.location.href = CONST.PATH.WHAT_WE_DO }}>Know More</div>
                </div>
          </div>

          {/* innovation on point PRODUCTDESIGN IMAGE Content using grid */}

          <div className="mid_secMb">
                <div className="title">{content.product_design_title}</div>
                <div className="topImg">
                  <img src={HomeImage3} alt="prodectdesign" />
                </div>
                <div className="contentMb">
                  <p className="desc">{content.product_design_desc}</p>
                  <div className="knowMore_btn">Know More</div>
                </div>
          </div>

        </div>
        }

        </div>

        {/* setting midbanner image size regarding to mobive view */}

        {isMobileView ?
          <Midbanner midBannerImg={MidBannerImgMb}  mb={isMobileView}/>
          :
        <Midbanner midBannerImg={MidBannerImg}  mb={isMobileView}/>
        }

        
        {/* We Walk the Untrodden Path in Pursuit of Excellence  PARAGRAPH*/}
        <div className="parentWraper">
          <div className="focusSec">
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} sm={12}>
                {isMobileView ? <div className="big_black_heading">{content.lower_middle_big_heading}</div>
                :
                <div className="big_black_heading heading">{content.lower_middle_big_heading}</div>}
                
              </Grid>
              <Grid item xs={12} lg={6} sm={12}>
                <p className="para">{content.lower_middle_desc}</p>
              </Grid>
            </Grid>
          </div>
        </div>

        {/* Content written in blue background*/}

        <div className="working_ways">
          <div className="big_white_heading" style={{ marginBottom: "70px" }}>{content.blue_backg_heading}</div>
          <Grid container>
            <Grid item xs={12} lg={6} sm={12}>
              <div className="green_title">{content.blue_backg_title1}</div>
              <p className="workingpara">{content.blue_backg_desc1}</p>
            </Grid>
            <Grid item xs={12} lg={6} sm={12}>
              <div className="green_title">{content.blue_backg_title2}</div>
              <p className="workingpara">{content.blue_backg_desc2}</p>
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid item xs={12} lg={6} sm={12}>
              <div className="green_title">{content.blue_backg_title3}</div>
              <p className="workingpara">{content.blue_backg_desc3}</p>
            </Grid>
            <Grid item xs={12} lg={6} sm={12}>
              <div className="green_title">{content.blue_backg_title4}</div>
              <p className="workingpara">{content.blue_backg_desc4}</p>
            </Grid>
          </Grid>

          {/* create button on this with on click event  */}

          <button className="working_btn" onClick={()=> window.location.href = CONST.PATH.CONTACT_US}>{content.demo_btn}</button>
        </div>

        {/* adding latest blog shows on Home Page */}

        <div className="carousel_wrap">
          <div className="black_heading">
            Latest Blog & News
          </div>
          {!isMobileView ?
          <Carousel items={BLOGDATA.businessBlog}/>
          :
          <CarouselMb items={BLOGDATA.businessBlog}/>
          }
        </div>

        {/* Client Review here */}

        <div className="slider_sec">
          <div className="big_black_heading">Hear from others<br/>
            How we’re making differences</div>
         
          <Slider />
        </div>
        <Lowerbanner lowerImage={LowerBannerImg} mb={isMobileView}/>
        <Footer mb={isMobileView}/>
      </React.Fragment>
    );
  }
}

export default LandingComponent;
