import React, { Component } from "react";
import './blogs.scss';
import Header from "../common/header/header";
import Footer from "../common/footer/footer";
import Pagination from '@mui/material/Pagination';
import { BLOGDATA } from "./blogs_data";
class Blogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobileView: false,
            blog_btn_index:0
        };
    }

    componentDidMount() {
        this.mobileView();
        window.addEventListener("resize", this.resizeWindow);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeWindow);
    }

    resizeWindow = () => {
        this.mobileView();
    };
    mobileView = () => {
        if (window.innerWidth < 560) {
            this.setState({
                isMobileView: true,
            });
        } else {
            this.setState({
                isMobileView: false,
            });
        }
    };
    handalePagination = (e,value) => {
        console.log(value);
    }
    switchBlog=(id)=>{
        this.setState({
            blog_btn_index:id
        })
    }
    isActiveBlog = (value) => {
        return (value === this.state.blog_btn_index ? "active_Blog_btn" : "blog_btn");
      }
    render() {
        const {
            isMobileView,
            blog_btn_index
        } = this.state;
        return (

            <React.Fragment>
                <Header mb={isMobileView} />
                <section className="blogWraper">
                    <div className="big_black_heading">We leave our footprints for
                        the others</div>
                        <div className="blog_btn_wrap">
                            <div id="left_btn_blog" style={isMobileView ? {fontSize:"12px"}:{}} className={this.isActiveBlog(0)} onClick={()=>this.switchBlog(0)}>BUSINESS AND IT</div>
                            <div id="right_btn_blog" style={isMobileView ? {fontSize:"12px"}:{}} className={this.isActiveBlog(1)} onClick={()=>this.switchBlog(1)}>TECHNICAL ARTICLES</div>
                        </div>
                    {blog_btn_index === 0 ?
                 <div
                 ><div className={isMobileView ? "blogs_wrap_mb" : "blogs_wrap"}>
                 {BLOGDATA.businessBlog.map((val, i) => {
                     return (
                         <div className="blog_cards" onClick={() => window.location.href = "/blog/"+ val.blogIndex}>
                             <div className="image" style={{ backgroundImage: `url(${val.imgPath})` }}></div>
                             <div className="text">
                                 <div className="heading">{val.title}</div>
                                 <div className="desc">{val.details}</div>
                             </div>
                         </div>
                     )
                 })}
                    </div>
                     </div>
                    :
                    <div>
                        <div className={isMobileView ? "blogs_wrap_mb" : "blogs_wrap"}>
                            {BLOGDATA.techBlogs.map((val, i) => {
                                return (
                                    <div className="blog_cards" onClick={() => window.open(val.link, "_blank")}>
                                        <div className="image" style={{ backgroundImage: `url(${val.imgPath})` }}></div>
                                        <div className="text">
                                            <div className="heading">{val.title}</div>
                                            <div className="desc">{val.details}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {/* <div className="pagination_wrap"> <Pagination count={10} variant="outlined" onChange={this.handalePagination} /></div> */}
                        </div>}
                </section>
                <Footer mb={isMobileView} />
            </React.Fragment>
        );
    }
}

export default Blogs;
