import React, { useState, useEffect } from "react";
import './header.scss';
import MahiraIcon from "../../../assets/images/mahiranew.svg";
import MenuIcon1 from "../../../assets/icons/MenuIcon1.svg";
import MenuIcon2 from "../../../assets/icons/MenuIcon2.svg";
import MenuIcon3 from "../../../assets/icons/MenuIcon3.svg";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { CONST } from '../../../shared/constant';
const HeaderComponent = (props) => {
  const [toggleDropdown, settoggleDropdown] = useState(false);
  const [toggleDropdownMb, settoggleDropdownMb] = useState(false);
  const [showHideMenu, setshowHideMenu] = useState(false);
  const [tabs, settabs] = useState(null);
  const [tabsMb, settabsMb] = useState(null);
  const navigatePages = (url) => {
    window.location.href = url;
  }
  const showDropdown = () => {
    settoggleDropdown(!toggleDropdown);
  }
  const showDropdown_mb = () => {
    settoggleDropdownMb(!toggleDropdownMb);
  }
  window.onscroll = function () { myFunction() };
  function myFunction() {
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    document.getElementById("myBar").style.width = scrolled + "%";
  }
  const toggleMenu = () => {
    setshowHideMenu(!showHideMenu);
    settoggleDropdownMb(false);
  }
  useEffect(() => {
    settabs(window.location.pathname)
    activeClass(window.location.pathname);
    activeClassMb(window.location.pathname);
    settabsMb(window.location.pathname);
  }, []);
  const activeClass = (val) => {
    return (val === tabs ? "active_tabs" : "tabs");
  }
  const activeClassMb = (val) => {
    return (val === tabsMb ? "active_tabs_mb" : "tabs_mb");
  }
  return (
    <React.Fragment>
      <section className="head_sec">
        <div className="headerWrap parentWraper">
          <div className="left_sec">
            <img src={MahiraIcon} alt="Mahira Technology" onClick={() => navigatePages(CONST.PATH.HOME)} />
          </div>
          {props.mb || window.innerWidth < 1360?
            <div className="right_sec_mb">
              {showHideMenu ? <MenuOpenIcon onClick={() => toggleMenu()} /> : <MenuIcon onClick={() => toggleMenu()} />}
            </div>
            :
            <div className="right_sec">
              <div className={activeClass(CONST.PATH.HOME)} onClick={() => navigatePages(CONST.PATH.HOME)}>{CONST.PAGE_NAME.HOME}</div>
              <div className={activeClass(CONST.PATH.WHAT_WE_DO)} onClick={() => navigatePages(CONST.PATH.WHAT_WE_DO)}>{CONST.PAGE_NAME.WHAT_WE_DO}
                {toggleDropdown &&
                  <div className="dropdown_wrap">
                    <div className="rows">
                      <div className="icon_wrap">
                        <img src={MenuIcon1} />
                      </div> <div className="icon_name" onClick={() => navigatePages(CONST.PATH.WHAT_WE_DO)}>DevOps</div>
                    </div>
                    <div className="rows" style={{ marginTop: "20px" }}>
                      <div className="icon_wrap"><img src={MenuIcon2} /></div> <div className="icon_name" onClick={() => navigatePages(CONST.PATH.WHAT_WE_DO)}>Cloud Technology</div>
                    </div>
                    <div className="rows" style={{ marginTop: "20px" }}>
                      <div className="icon_wrap"> <img src={MenuIcon3} /></div> <div className="icon_name">Product Design</div>
                    </div>
                  </div>
                }
              </div>
              <div className={activeClass(CONST.PATH.WHO_WE_ARE)} onClick={() => navigatePages(CONST.PATH.WHO_WE_ARE)}>{CONST.PAGE_NAME.WHO_WE_ARE}</div>
              <div className={activeClass(CONST.PATH.CAREER)} onClick={() => navigatePages(CONST.PATH.CAREER)}>{CONST.PAGE_NAME.CAREER}</div>
              <div className={activeClass(CONST.PATH.BLOGS)} onClick={() => navigatePages(CONST.PATH.BLOGS)}>{CONST.PAGE_NAME.BLOGS}</div>
              <div className={activeClass(CONST.PATH.CONTACT_US)} onClick={() => navigatePages(CONST.PATH.CONTACT_US)}>{CONST.PAGE_NAME.CONTACT_US}</div>
            </div>
          }
        </div>
        <div className="header">
          <div className="progress-container">
            <div className="progress-bar" id="myBar"></div>
          </div>
        </div>
        {showHideMenu &&
          <div className="menu_area">
            <div className={activeClassMb(CONST.PATH.HOME)} onClick={() => navigatePages(CONST.PATH.HOME)}>{CONST.PAGE_NAME.HOME}</div>
            <div className={activeClassMb(CONST.PATH.WHAT_WE_DO)} onClick={() => navigatePages(CONST.PATH.WHAT_WE_DO)}>{CONST.PAGE_NAME.WHAT_WE_DO}
              {toggleDropdownMb &&
                <div className="dropdown_wrap_mb">
                  <div className="rows">
                    <div className="circle"></div> <div className="icon_name">DevOps</div>
                  </div>
                  <div className="rows">
                    <div className="circle"></div><div className="icon_name" onClick={() => navigatePages(CONST.PATH.WHAT_WE_DO)}>Cloud Technology</div>
                  </div>
                  <div className="rows">
                    <div className="circle"></div> <div className="icon_name">Product Design</div>
                  </div>
                </div>
              }
            </div>
            <div className={activeClassMb(CONST.PATH.WHO_WE_ARE)} onClick={() => navigatePages(CONST.PATH.WHO_WE_ARE)}>{CONST.PAGE_NAME.WHO_WE_ARE}</div>
            <div className={activeClassMb(CONST.PATH.CAREER)} onClick={() => navigatePages(CONST.PATH.CAREER)}>{CONST.PAGE_NAME.CAREER}</div>
            <div className={activeClassMb(CONST.PATH.BLOGS)} onClick={() => navigatePages(CONST.PATH.BLOGS)}>{CONST.PAGE_NAME.BLOGS}</div>
            <div className={activeClassMb(CONST.PATH.CONTACT_US)} onClick={() => navigatePages(CONST.PATH.CONTACT_US)}>{CONST.PAGE_NAME.CONTACT_US}</div>
          </div>
        }
      </section>
    </React.Fragment>
  );
};
export default HeaderComponent;
