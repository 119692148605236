import React from "react";
import "./banner.scss";
import Grid from '@mui/material/Grid';
import {CONST} from "../../../shared/constant"
const BannerComponent = (props) => {
  

  return (
    <React.Fragment>
      <section className="parentWraper">
      {!props.mb ? 
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6} sm={6}>
            <div className="banner_left">
              <div className="big_black_heading">{props.heading}</div>
              <div className="banner_subtext">{props.subText}</div>
              <button className="banner_btn" onClick={()=> window.location.href = CONST.PATH.CONTACT_US}>{props.btnText}</button>
              </div>
       </Grid>
       <Grid item xs={12} lg={6} sm={6}>
         <div className="bannerimg_wrap">
          <img src={props.bannerImage} />
          </div>
        </Grid>
      </Grid>
      :
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6} sm={6}>
        <div className="bannerimgMb_wrap">
          <img src={props.bannerImage} />
          </div>
       </Grid>
       <Grid item xs={12} lg={6} sm={6}>
          <div className="banner_left_mb">
              <div className="big_black_headingMb">{props.heading}</div>
              <div className="banner_subtext">{props.subText}</div>
              <button className="banner_btn" onClick={()=> window.location.href = CONST.PATH.CONTACT_US}>{props.btnText}</button>
              </div>
        </Grid>
      </Grid>
      }
      
      </section>
    </React.Fragment>
  );
};
export default BannerComponent;
