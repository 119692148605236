import React, { Component } from "react";
import "./cloudTechnology.scss";
import Header from "../common/header/header";
import Banner from "../common/banner/banner";
import Footer from "../common/footer/footer";
import Midbanner from "../common/midBanner/midBanner";
import Lowerbanner from "../common/lowerBanner/lowerBanner";
import Carousel from "../common/carousel/carousel";
import CarouselMb from "../common/carouselMb/carouselMb";
import Grid from '@mui/material/Grid';
import MidBannerImg from "../../assets/images/midBannerCloud.png";
import MidBannerImgMb from "../../assets/images/mblbanner2.png";
import BannerImg from "../../assets/images/CloudBanner.png";
import LowerBannerImg from "../../assets/images/FooterImage.png";
import ChevronUp from "../../assets/icons/Vector.svg";
import ChevronDown from "../../assets/icons/Vector_orange.svg";
// import AWS from "../../assets/images/aws.png";
// import GCP from "../../assets/images/gcp.png";
// import Azure from "../../assets/images/azure.png";
import { BLOGDATA } from "../blogs/blogs_data";
import Logoslider from "../common/logoslider/logoslider"
class CloudTechnology extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accord_data: [],
      selected_Accordian: null,
      big_cards:[],
      banner_heading: "Build for tomorrow, deploy anywhere & scale for future.",
      banner_subtext: "Shift to the cloud seamlessly or re-architect for the cloud-native environment with Mahira Technology cloud modernisation services to fuel your future growth.",
      isMobileView: false
    };
  }
  componentDidMount() {
    var jsonData = require("../../data/content.json");
    this.setState({
      accord_data: jsonData.cloud_technology.accordian,
      big_cards: jsonData.cloud_technology.big_cards
    })
    this.mobileView();
    window.addEventListener("resize", this.resizeWindow);
  }
  isActiveAccordian = (value) => {
    return (value === this.state.selected_Accordian ? "active_Accordian" : "close_Accordian");
  }
  handaleAccordian = (value,ind) => {
    if (value !== this.state.selected_Accordian) {
      this.setState({
        selected_Accordian: value
      },()=>{
        this.scrollTo(ind)
      })
    } else {
      this.setState({
        selected_Accordian: null
      })
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeWindow);
  }

  resizeWindow = () => {
    this.mobileView();
  };
  mobileView = () => {
    if (window.innerWidth < 560) {
      this.setState({
        isMobileView: true,
      });
    } else {
      this.setState({
        isMobileView: false,
      });
    }
  };
  scrollTo =(id)=> {
    var element = document.getElementById(id);
    var headerOffset = 100;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }
  render() {
    const {
      banner_heading,
      banner_subtext,
      accord_data,
      big_cards,
      isMobileView
    } = this.state;
    return (
      <React.Fragment>
        <Header mb={isMobileView}/>
        <Banner heading={banner_heading} subText={banner_subtext} bannerImage={BannerImg} mb={isMobileView} btnText="Schedule a Demo"/>
     
        <div className="logo_sec">
          <div className={isMobileView ? "big_black_headingMb": "big_black_heading"} style={{marginBottom:"70px"}}>Futuristic cloud engagement model and 
advisory helps enterprises in transformation, 
optimal cloud adoption and usage</div>
        <Logoslider mb={isMobileView}/>
        </div>
        <div className="card_accordian_sec">
          <div className="first_para">Expand business digitally, Eliminate complexities rapidly, Experience cloud profitably.</div>
          {/* <div className="small_cards">
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3} sm={6}>
                <div className="card">
                  <div className="title">Infrastructure
                    as a service (IaaS)</div>
                  <div className="know_more">Know More</div>
                </div>
              </Grid>
              <Grid item xs={12} lg={3} sm={6}>
                <div className="card">
                  <div className="title">Serverless
                    Computing</div>
                  <div className="know_more">Know More</div>
                </div>
              </Grid>
              <Grid item xs={12} lg={3} sm={6}>
                <div className="card">
                  <div className="title">Platform
                    as a service (PaaS)</div>
                  <div className="know_more">Know More</div>
                </div>
              </Grid>
              <Grid item xs={12} lg={3} sm={6}>
                <div className="card">
                  <div className="title">Software
                    as a service (SaaS)</div>
                  <div className="know_more">Know More</div>
                </div>
              </Grid>
            </Grid>
          </div> */}
          <div className="accord_wrap">
            {accord_data && accord_data.map((value, index) => {
              return (
                <div id={"accordian" + index} className="accordianSection">
                  <div className={this.isActiveAccordian(index) === "active_Accordian" ? "active_title" : "accordian_title"} onClick={() => this.handaleAccordian(index,"accordian" + index)}>
                    <span className="accord_title">{value.title}</span>
                    {this.isActiveAccordian(index) === "active_Accordian" ?
                      <span className="chevron_icon">
                        {!isMobileView && <span className="orange_text">Know More</span>} <img src={ChevronDown} />

                      </span>
                      :
                      <span className="chevron_icon">
                       {!isMobileView &&  <span className="blue_text">Know More</span>} <img src={ChevronUp} />

                      </span>
                    }
                  </div>
                  <div className={this.isActiveAccordian(index)}>
                    {/* {value.desc.map((j, k) => {
                      return (
                        <React.Fragment>
                          <div className="details">
                            <div className="circle"></div>  <div className="text">{j}</div>
                          </div>
                        </React.Fragment>
                      )
                    })} */}
                    <div className="para"><p>{value.details}</p></div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {isMobileView ?
          <Midbanner midBannerImg={MidBannerImgMb}  mb={isMobileView}/>
          :
        <Midbanner midBannerImg={MidBannerImg}  mb={isMobileView}/>
        }
        {/* <div className="public_services">
          <div className="big_black_heading">Public Cloud Services and Support</div>
          <Grid container spacing={2} style={{marginTop:"70px"}}>
              <Grid item xs={12} lg={4} sm={4}>
                <div className="img_wrap">
                <img src={AWS} />
                </div>
              </Grid>
              <Grid item xs={12} lg={4} sm={4}>
                <div className="img_wrap">
                <img src={GCP} />  
                </div>
              </Grid>
              <Grid item xs={12} lg={4} sm={4}>
                <div className="img_wrap">
                <img src={Azure} />
                </div>
              </Grid>
            </Grid>
        </div> */}
        {/* <div className="big_card_wrap">
          <div className={isMobileView ? "headingMb": "heading"}>Transition to modern, agile, and digitally connected workplace
            MS OFFICE 365</div>
          <div className={isMobileView ? "cards_wrap_mb" : "cards_wrap"}>
            {big_cards && big_cards.length > 0 && big_cards.map((val, i) => {
              return (
                <div className="cards">
                  <div className="heading_black">{val.label}</div>
                  {val.points.map((j, k) => {
                    return (
                  <div className="description"><div className="circle"></div><div className="text">{j}</div></div>
                  )
                })}
                </div>
              )
            })}
          </div>
        </div> */}
        {/* <div className="carousel_Cloudwrap">
          <div className="black_heading">
          Case Studies
          </div>
          {!isMobileView ?
          <Carousel items={BLOGDATA.techBlogs}/>
          :
          <CarouselMb items={BLOGDATA.techBlogs}/>
          }
        </div> */}
        <Lowerbanner lowerImage={LowerBannerImg} mb={isMobileView}/>
        <Footer mb={isMobileView}/>
      </React.Fragment>
    );
  }
}

export default CloudTechnology;
