import React, { useEffect, useState } from "react";
import '../common/logoslider/logoslider.scss';
import Grid from '@mui/material/Grid'; 
import Arrowleft2 from "../../assets/icons/ArrowLeft2.svg"   
import Arrowright2 from "../../assets/icons/ArrowRight2.svg" 
import Clientsimg1 from "../../assets/images/pharmait.png";
import Clientsimg3 from "../../assets/images/newpage.png";
import Clientsimg4 from "../../assets/images/labs.png";
import Clientsimg2 from "../../assets/images/flycatch.svg";
import Clientsimg5 from "../../assets/images/dntnew.png"; 
import Clientsimg6 from "../../assets/images/deliforcenew.png";
import Clientsimg7 from "../../assets/images/vayuznew.png";
import Clientsimg8 from "../../assets/images/nextbrainnew.png";
import Clientsimg9 from "../../assets/images/Autimo_logo.png";
 
const Logoslider1 = (props) => {
    const [carouselData, setcarouselData] = useState([
        Clientsimg1,Clientsimg2,Clientsimg3,Clientsimg4,Clientsimg5,Clientsimg6,Clientsimg7,Clientsimg8
    ]);
  
    const [currentData, setcurrentData] = useState([
        {
            imgPath: ""
          },
          {
            imgPath: ""
          },
          {
            imgPath: ""
          },
          {
            imgPath: ""
          }
    ]);
    const [currentDataMb, setcurrentDataMb] = useState([
      {
        imgPath: ""
      },
      {
        imgPath: ""
      },
      {
        imgPath: ""
      },
      {
        imgPath: ""
      }
  ]);
    const [carouselIndex, setcarouselIndex] = useState({
        start: 0,
        end: 5
      });
      const [mobileIndex, setmobileIndex] = useState({
        mbstart: 0,
        mbend: 1
      });
    useEffect(() => {
        const {start, end} = carouselIndex;
        const {mbstart, mbend} = mobileIndex;
        if(props.mb){
          generateDataMb(carouselData,mbstart,mbend);
        }else{
        generateData(carouselData,start,end);
        }
    }, [props.mb]);

    const generateDataMb =(data,start,end)=>{
      setcurrentDataMb(data.slice(start,end));
     }
    const generateData =(data,start,end)=>{
        setcurrentData(data.slice(start,end));
    }
    const moveLeft =()=>{
        const {start, end} = carouselIndex;
        if(start > 0){
        generateData(carouselData,start - 1,end - 1);
        setcarouselIndex({
            ...carouselIndex,
            start: start - 1,
            end: end - 1
          });
        }
    }
    const moveRight =()=>{
        
        const {start, end} = carouselIndex;
        if(end < carouselData.length){
        generateData(carouselData,start + 1,end + 1);
        setcarouselIndex({
            ...carouselIndex,
            start: start+1,
            end: end+1
          });
        }
    }
    const moveLeftMb =()=>{
      const {mbstart, mbend} = mobileIndex;
      if(mbstart > 0){
        generateDataMb(carouselData,mbstart - 1,mbend - 1);
      setmobileIndex({
          ...mobileIndex,
          mbstart: mbstart - 1,
          mbend: mbend - 1
        });
      }
  }
  const moveRightMb =()=>{
      
    const {mbstart, mbend} = mobileIndex;
      if(mbend < carouselData.length){
        generateDataMb(carouselData,mbstart + 1,mbend + 1);
      setmobileIndex({
          ...mobileIndex,
          mbstart: mbstart+1,
          mbend: mbend+1
        });
      }
  }
    return (
        <React.Fragment>
          
          {!props.mb ?
          
            <div className="logoslider_sec">
            <center>
            <Grid container spacing={4}>
            <Grid item xs={1} lg={1} >
            <div className="left_btn" onClick={()=>moveLeft()}><img className="btn_img" src={Arrowleft2}/></div>
              </Grid>
              
              <Grid item xs={12} lg={2} sm={2}>
              <div className="logos">
                 <img src={currentData[0]}/>
              </div>
              </Grid>
              <Grid item xs={12} lg={2} sm={2} >
              <div className="logos" >
              <img src={currentData[1]}/>
              </div>
              </Grid>
              <Grid item xs={12} lg={2} sm={2}>
              <div className="logos" >
              <img src={currentData[2]}/>
              </div>
              </Grid>
              <Grid item xs={12} lg={2} sm={2}>
             
              <div className="logos">
              <img src={currentData[3]}/>
              </div>
              </Grid>
              <Grid item xs={12} lg={2} sm={2}>
             
              <div className="logos">
              <img src={currentData[4]}/>
              </div>
              </Grid>
              <Grid item xs={1} lg={1}>
              <div className="right_btn" onClick={()=>moveRight()}><img className="btn_img" src={Arrowright2}/></div>
              </Grid>
             
            </Grid>
            </center>
            </div>
             :
            <div className="logoslider_sec">
            <Grid container spacing={4}>
            <Grid item xs={3}>
            <div className="left_btn" onClick={()=>moveLeftMb()}><img src={Arrowleft2}/></div>
              </Grid>
              <Grid item xs={3}>
              <div className="logos">
                 <img src={currentDataMb[0]}/>
              </div>
              </Grid>
              <Grid item xs={3}>
              <div className="logos" >
              <img src={currentDataMb[1]}/>
              </div>
              </Grid> 
               <Grid item xs={3}>
              <div className="logos" >
              <img src={currentDataMb[2]}/>
              </div>
              </Grid> 
              <Grid item xs={3}>
              <div className="right_btn" onClick={()=>moveRightMb()}><img src={Arrowright2}/></div>
              </Grid>
             
            </Grid>
            
            </div>}
            

        </React.Fragment>
    );
};

export default Logoslider1;
