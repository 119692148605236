import React from 'react';
import './App.scss';
import Routing from './app/routing';
function App() {
  return (
    <div>
      <Routing></Routing>
    </div>
  );
}

export default App;
