import React from "react";
import './pagenotfound.scss';

const PageNotFound = (props) => {
  
  return (
    <React.Fragment>
        <section className="pagenotfound_wrap">
                <div className="big_black_heading">Oops! Page Not Found</div>
                <button onClick={()=> window.location.href = "/"}>Go To Home</button>
        </section>
    </React.Fragment>
  );
};
export default PageNotFound;
