import React, { useEffect, useState } from "react";
import './slider.scss';
import ClientImg from "../../../assets/images/client_img.png";
import ArrowLeft from "../../../assets/icons/ArrowLeft1.svg"
import ArrowRight from "../../../assets/icons/ArrowRight1.svg"
const SliderComponent = (props) => {
    const [sliderData, setsliderData] = useState([]);
    const [currentsliderData, setcurrentsliderData] = useState({});
    const [currentIndex, setcurrentIndex] = useState(0);
    useEffect(() => {
        var jsonData = require("../../../data/content.json");
        setsliderData(jsonData.landing_page.slider)
        setcurrentsliderData(jsonData.landing_page.slider[0])
    }, []);
    const goPrevious =  () => {
        if(currentIndex > 0){
         setcurrentsliderData(sliderData[currentIndex - 1]);
         setcurrentIndex(currentIndex - 1)
        }
    }
    const goNext = async() => {
        if(currentIndex < sliderData.length - 1){
            setcurrentsliderData(sliderData[currentIndex + 1]);
           await setcurrentIndex(currentIndex + 1)
        }
    }
    return (
        <React.Fragment>
             <div className="text">{currentsliderData.client_mesg} </div>
            <div className="slider_wrap">
               
                            {/* <div className="image"><img src={ClientImg} /></div> */}
                            <div className="name">{currentsliderData.client_name}</div>
                            <div className="company">{currentsliderData.company_name}</div>

                <div className="btn_wrap">
                    <div onClick={() => goPrevious()}><img src={ArrowLeft} /></div>
                    <div className="numbers"><span className="current">{currentIndex + 1}</span> <span className="divide">/</span> <span className="total">{sliderData.length}</span></div>
                    <div onClick={() => goNext()}><img src={ArrowRight} /></div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default SliderComponent;
