import React, { Component } from "react";
import "./career.scss";
import Parser from "html-react-parser";
import Header from "../common/header/header";
import Banner from "../common/banner/banner";
import Footer from "../common/footer/footer";
import Midbanner from "../common/midBanner/midBanner";
import Lowerbanner from "../common/lowerBanner/lowerBanner";
import Carousel from "../common/carousel/carousel";
import CarouselMb from "../common/carouselMb/carouselMb";
import Grid from '@mui/material/Grid';
import MidBannerImg from "../../assets/images/midBannerCareer.png";
import MidBannerImgMb from "../../assets/images/mblbanner4.png";
import BannerImg from "../../assets/images/CareerBanner.png";
import LowerBannerImg from "../../assets/images/FooterImage.png";
import ChevronUp from "../../assets/icons/Vector.svg";
import ChevronDown from "../../assets/icons/Vector_orange.svg";
import { BLOGDATA } from "../blogs/blogs_data";

class CareerComponent extends Component {
  constructor(props) {
    super(props);
    this.createReference();
    this.state = {
      accord_data: [],
      selected_Accordian: null,
      banner_heading: "Choose to stand out, and we'll make you standout",
      banner_subtext: "Bornfight is a new breed of business — it's a framework which enables you to maximize the value you get out of it and for yourself.",
      isMobileView: false
    };
  }
  createReference(){
    var jsonData = require("../../data/content.json");
    jsonData.career.accordian.forEach((val,i)=>{
       this[`${val.refName}`] = React.createRef();
    })
  }
  componentDidMount() {
    var jsonData = require("../../data/content.json");
    this.setState({
      accord_data: jsonData.career.accordian,
    })
    this.mobileView();
    window.addEventListener("resize", this.resizeWindow);
  }
  isActiveAccordian = (value) => {
    return (value === this.state.selected_Accordian ? "active_Accordian" : "close_Accordian");
  }
  handaleAccordian = (value,refName,id) => {
    if (value !== this.state.selected_Accordian) {
      this.setState({
        selected_Accordian: value
      },()=>{
        this.scrollTo(refName,id)
      })
     
    } else {
      this.setState({
        selected_Accordian: null
      })
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeWindow);
  }

  resizeWindow = () => {
    this.mobileView();
  };
  mobileView = () => {
    if (window.innerWidth < 560) {
      this.setState({
        isMobileView: true,
      });
    } else {
      this.setState({
        isMobileView: false,
      });
    }
  };
  scrollTo = async(refname,id)=> {
    console.log("<><><>",this[refname]);
    var element = document.getElementById(id);
    var headerOffset = 100;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
    //window.scrollTo({top: this[refname].current.offsetTop - 105,behavior: "smooth"});
    // var elmnt = document.getElementById(id);
    // let x = Promise.resolve(elmnt.scrollIntoView({
    //   block: "start",
    //   behavior: "smooth",
    // }));
    // await x;
  }
  render() {
    const {
      banner_heading,
      banner_subtext,
      accord_data,
      isMobileView
    } = this.state;
    return (
      <React.Fragment>
        <Header mb={isMobileView}/>
        <Banner heading={banner_heading} subText={banner_subtext} bannerImage={BannerImg} mb={isMobileView} btnText="Join Our Team"/>
        <div className="greytextband">
            <div className="big_black_heading">When you join us, we'll set up your personal framework for achieving rapid professional growth.</div>
            <div className="gey_para">You'll get transparent access to business information, and the power to propose, 
        implement and spearhead new ideas and initiatives. We'll enable you to achieve 
        continuous and ever-growing success, while shaping the way we all move forward.</div>
        </div>
          {isMobileView ?
          <Midbanner midBannerImg={MidBannerImgMb}  mb={isMobileView}/>
          :
        <Midbanner midBannerImg={MidBannerImg}  mb={isMobileView}/>
        }
          <div className="midText_wrap">
                <div className="big_black_heading heading">
                Whatever you value, you will get the most of it.
                </div>
                <div className="text">
                Everything we do revolves around providing maximum value. Just as we do it for our clients, we also do it for our team.
                </div>
            <div className="lines_wrap">
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4} sm={4}>
              <div className="circle"></div>
                <div className="lines">
                Clear advancement plans
                </div>
              </Grid>
              <Grid item xs={12} lg={4} sm={4}>
              <div className="circle"></div>
                <div className="lines">
                Fast professional growth
                </div>
              </Grid>
              <Grid item xs={12} lg={4} sm={4}>
              <div className="circle"></div>
                <div className="lines">
                Access to the latest technology
                </div>
              </Grid>
            </Grid>
            
            <Grid container spacing={2} style={{marginTop:"24px"}}>
              <Grid item xs={12} lg={4} sm={4}>
              <div className="circle"></div>
                <div className="lines">
                Opportunity to lead people
                </div>
              </Grid>
              <Grid item xs={12} lg={4} sm={4}>
              <div className="circle"></div>
                <div className="lines">
                Working on innovative products
                </div>
              </Grid>
              <Grid item xs={12} lg={4} sm={4}>
              <div className="circle"></div>
                <div className="lines">
                Adaptable work schedule
                </div>
              </Grid>
            </Grid>
            </div>
          </div>
          <div className="card_accordian_career">
              <div className="big_black_heading">Open Positions</div>
          <div className="accord_wrap_career">
            {accord_data && accord_data.map((value, index) => {
              return (
                <div id={"accord" + index} className="accordianSection" ref={this[`${value.refName}`]}>
                  <div className={this.isActiveAccordian(index) === "active_Accordian" ? "active_title" : "accordian_title"} onClick={() => this.handaleAccordian(index,value.refName,"accord" + index)}>
                    <span className="accord_title">{value.title}</span>
                    {this.isActiveAccordian(index) === "active_Accordian" ?
                      <span className="chevron_icon">
                        {!isMobileView && <span className="orange_text">Know More</span>} <img src={ChevronDown} />

                      </span>
                      :
                      <span className="chevron_icon">
                       {!isMobileView &&  <span className="blue_text">Know More</span>} <img src={ChevronUp} />

                      </span>
                    }
                  </div>
                  <div className={this.isActiveAccordian(index)}>
                    {/* {value.desc.map((j, k) => {
                      return (
                        <React.Fragment>
                          <div className="details">
                            <div className="circle"></div>  <div className="text">{j}</div>
                          </div>
                        </React.Fragment>
                      )
                    })} */}
                    <div className="json_content">
                      {Parser(value.jd)}
                      </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
       
        {/* <div className="carousel_Cloudwrap">
          <div className="black_heading">
          Case Studies
          </div>
          {!isMobileView ?
          <Carousel items={BLOGDATA.techBlogs}/>
          :
          <CarouselMb items={BLOGDATA.techBlogs}/>
          }
        </div> */}
        <Lowerbanner lowerImage={LowerBannerImg} mb={isMobileView}/>
        <Footer mb={isMobileView}/>
      </React.Fragment>
    );
  }
}

export default CareerComponent;
